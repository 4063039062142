import React from "react";
import { useRecordContext } from "react-admin";
import get from "lodash/get";
import { getProfilePictureSrc } from "../../../utils";

const AWSImageField = (props) => {
  const { src, size, circle } = props;
  let record = props.record || useRecordContext(props);
  const value = get(record, src);

  return (
    <div
      className={`awsImageField--size-${size || "tiny"} ${
        circle ? "circle" : ""
      }`}
    >
      {Array.isArray(value) ? (
        <div className="awsImageField--array">
          {value.map((item, index) => (
            <div key={index} className="awsImageField--array-item">
              <img
                key={index}
                alt=""
                src={getProfilePictureSrc(item?.image, size || "tiny")}
              />
            </div>
          ))}
        </div>
      ) : (
        <img alt="" src={getProfilePictureSrc(value, size || "tiny")} />
      )}
    </div>
  );
};

export default AWSImageField;
