import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  SelectInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
} from 'react-admin';
import {AWSImageInput, CustomDateInput} from '../components';

const PartnerTitle = ({record}) =>
  record ? (
    <span>
      {record.firstName} {record.lastName}
    </span>
  ) : null;

const PartnerEdit = (props) => {
  const idolOptionRenderer = (row) => {
    return `${row?.firstName || '-'} ${row?.lastName || '-'}`;
  };
  return (
    <Edit {...props} title={<PartnerTitle />}>
      <SimpleForm>
        <AWSImageInput />

        <TextInput source="name" />
        <TextInput source="username" />
        <TextInput source="email" />
        <TextInput source="callingCode" />
        <TextInput source="mobileNumber" />

        <ReferenceArrayInput source="idolIds" reference="Idol">
          <AutocompleteArrayInput optionText={idolOptionRenderer} />
        </ReferenceArrayInput>

        <TextInput source="balance" />
        <TextInput source="taxId" />
        <TextInput source="businessRegistration" />
        <TextInput source="iban" />
        <TextInput source="bic" />
        <TextInput multiline source="about" />
        <SelectInput
          source="verificationStatus"
          choices={[
            {id: 'initialized', name: 'Initialized'},
            {id: 'pending', name: 'Pending'},
            {id: 'successful', name: 'Successful'},
          ]}
        />
        <BooleanInput source="profileCompleted" />
        <TextInput source="password" />
      </SimpleForm>
    </Edit>
  );
};

export default PartnerEdit;
