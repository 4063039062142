import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  Filter,
  TextInput,
  FunctionField,
  EmailField,
} from 'react-admin';

import {
  UserNameReference,
  UserPictureReference,
  CustomDateField,
} from '../components';

const PaymentFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="word" alwaysOn />
  </Filter>
);

const PaymentList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <UserPictureReference />
      <UserNameReference />
      <TextField source="amount" />
      <TextField source="gateway" emptyText="-" />
      <TextField source="status" />
      <CustomDateField source="created" showTime />
    </Datagrid>
  </List>
);

export default PaymentList;
