import React from "react";
import { Edit, SimpleForm, TextInput, BooleanInput } from "react-admin";

const CharityTitle = ({ record }) =>
  record ? <span>{record.name}</span> : null;

const CharityEdit = (props) => (
  <Edit {...props} title={<CharityTitle />}>
    <SimpleForm>
      <TextInput readOnly source="id" />
      <TextInput source="name" />
      <TextInput source="description" />
      <TextInput source="website" />
      <BooleanInput source="deleted" />
    </SimpleForm>
  </Edit>
);

export default CharityEdit;
