import React from "react";
import { useRecordContext, ReferenceField } from "react-admin";
import { AWSImageField } from "../image";

const UserPictureReference = (props) => {
  const record = useRecordContext();
  const { node } = props;

  const source = `${node || "user"}.id`;
  const reference = record?.[node || "user"]?.type === "idol" ? "Idol" : "User";

  if (!source || !reference) {
    return (
      <AWSImageField record={record} circle src={`${node || "user"}.picture`} />
    );
  }

  return (
    <a href={`https://admin.bloggercube.com/#/${reference}/${record[source]}`}>
      <AWSImageField record={record} circle src={`${node || "user"}.picture`} />
    </a>
  );
};

export default UserPictureReference;
