import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  BooleanInput,
  ReferenceInput,
} from 'react-admin';
import {CustomDateInput} from '../components';

const IdolCreate = (props) => (
  <Create {...props} title="Create User">
    <SimpleForm>
      <TextInput source="firstName" />
      <TextInput source="lastName" />
      <TextInput source="username" />
      <TextInput source="email" />
      <TextInput source="callingCode" />
      <TextInput source="mobileNumber" />
      <ReferenceInput
        label="Category"
        source="categoryId"
        reference="Category"
        filter={{deleted: false}}>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput source="balance" />
      <TextInput source="taxId" />
      <TextInput source="businessRegistration" />
      <TextInput source="iban" />
      <TextInput source="bic" />
      <TextInput multiline source="about" />
      <CustomDateInput source="birthDate" />
      <SelectInput
        source="gender"
        choices={[
          {id: 'male', name: 'Male'},
          {id: 'female', name: 'Female'},
          {id: 'diverse', name: 'Diverse'},
        ]}
      />
      <SelectInput
        source="verificationStatus"
        choices={[
          {id: 'initialized', name: 'Initialized'},
          {id: 'pending', name: 'Pending'},
          {id: 'successful', name: 'Successful'},
        ]}
      />
      <BooleanInput source="profileCompleted" />
      <BooleanInput source="featured" />
      <TextInput source="password" />
    </SimpleForm>
  </Create>
);

export default IdolCreate;
