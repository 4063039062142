import gql from "graphql-tag";

const GET_UPLOAD_URL = gql`
  mutation GetSignedUploadUrlMutation($input: UploadInput!) {
    getSignedUploadUrl(input: $input) {
      status
      data {
        data
        id
      }
      error {
        message
        code
        status
      }
    }
  }
`;

const ACCEPT_WITHDRAWAL = gql`
  mutation AcceptWithdrawalMutation($id: ID!) {
    acceptWithdrawal(id: $id) {
      id
    }
  }
`;

const REJECT_WITHDRAWAL = gql`
  mutation RejectWithdrawalMutation($id: ID!) {
    rejectWithdrawal(id: $id) {
      id
    }
  }
`;

export { GET_UPLOAD_URL, ACCEPT_WITHDRAWAL, REJECT_WITHDRAWAL };
