import React from 'react';
import {Create, SimpleForm, TextInput} from 'react-admin';

const CategoryCreate = (props) => (
  <Create {...props} title="Create Category">
    <SimpleForm>
      <TextInput source="name" />
    </SimpleForm>
  </Create>
);

export default CategoryCreate;
