import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  RadioButtonGroupInput,
} from 'react-admin';
import {CustomDateInput} from '../components';

const UserCreate = (props) => (
  <Create {...props} title="Create User">
    <SimpleForm>
      <TextInput source="firstName" />
      <TextInput source="lastName" />
      <TextInput source="email" />
      <TextInput source="callingCode" />
      <TextInput source="mobileNumber" />
      <TextInput source="balance" />
      <CustomDateInput source="birthDate" />

      <RadioButtonGroupInput
        source="gender"
        choices={[
          {id: 'male', name: 'Male'},
          {id: 'female', name: 'Female'},
          {id: 'diverse', name: 'Diverse'},
        ]}
      />
      <TextInput source="password" />
    </SimpleForm>
  </Create>
);

export default UserCreate;
