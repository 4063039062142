import gql from "graphql-tag";

const GAIC_QUERY = gql`
  query gaic($title: String, $language: String) {
    gaic(title: $title, language: $language) {
      content
      error {
        code
        message
      }
    }
  }
`;

export { GAIC_QUERY };
