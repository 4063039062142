import React from "react";
import { useRecordContext } from "react-admin";
import get from "lodash/get";
import { getThumbnailSrc } from "../../../utils";

const AWSThumbnailField = (props) => {
  const { src, size, circle } = props;
  let record = useRecordContext(props);
  const value = get(record, src);

  if (props.record) {
    record = props.record;
  }

  return (
    <div
      className={`awsThumbnailField--size-${size || "medium"} ${
        circle ? "circle" : ""
      }`}
    >
      <img alt="" src={getThumbnailSrc(value, size || "medium")} />
    </div>
  );
};

export default AWSThumbnailField;
