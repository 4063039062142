import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  BooleanInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
} from 'react-admin';
import {CustomDateInput} from '../components';

const PartnerCreate = (props) => {
  const idolOptionRenderer = (row) => {
    return `${row.firstName || '-'} ${row.lastName || '-'}`;
  };

  return (
    <Create {...props} title="Create User">
      <SimpleForm>
        <TextInput source="name" />
        <TextInput source="username" />
        <TextInput source="email" />
        <TextInput source="callingCode" />
        <TextInput source="mobileNumber" />

        <ReferenceArrayInput source="idolIds" reference="Idol">
          <AutocompleteArrayInput optionText={idolOptionRenderer} />
        </ReferenceArrayInput>

        <TextInput source="balance" />
        <TextInput source="taxId" />
        <TextInput source="businessRegistration" />
        <TextInput source="iban" />
        <TextInput source="bic" />
        <TextInput multiline source="about" />
        <SelectInput
          source="verificationStatus"
          choices={[
            {id: 'initialized', name: 'Initialized'},
            {id: 'pending', name: 'Pending'},
            {id: 'successful', name: 'Successful'},
          ]}
        />
        <BooleanInput source="profileCompleted" />
        <TextInput source="password" />
      </SimpleForm>
    </Create>
  );
};

export default PartnerCreate;
