import { CardActions } from "@mui/material";
import React from "react";
import {
  List,
  Datagrid,
  TextField,
  Filter,
  TextInput,
  BooleanField,
  CreateButton,
} from "react-admin";
import { CustomDateField } from "../components";
import { AWSThumbnailField } from "../components/image";

const Actions = (props) => (
  <CardActions style={{ alignSelf: "center" }}>
    <CreateButton />
  </CardActions>
);

const PageFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="word" alwaysOn />
  </Filter>
);

const PageList = (props) => (
  <List {...props} filters={<PageFilter />} actions={<Actions />}>
    <Datagrid rowClick="edit">
      <AWSThumbnailField src="thumbnail" size="tiny" />
      <TextField source="type" />
      <TextField source="title" />
      <TextField source="locale" />
      <TextField source="path" />
      <CustomDateField source="updated" />
      {/* <CustomDateField source="created" /> */}
      {/* <BooleanField source="deleted" /> */}
    </Datagrid>
  </List>
);

export default PageList;
