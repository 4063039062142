import React from "react";
import { Edit, SimpleForm, BooleanInput, TextInput } from "react-admin";

const FeedbackTitle = ({ record }) =>
  record ? <span>{record.message}</span> : null;

const FeedbackList = (props) => (
  <Edit {...props} title={<FeedbackTitle />}>
    <SimpleForm>
      <TextInput readOnly source="id" />
      <TextInput source="message" />
      <BooleanInput source="deleted" />
    </SimpleForm>
  </Edit>
);

export default FeedbackList;
