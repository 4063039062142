import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  Filter,
  TextInput,
  CreateButton,
} from "react-admin";
import { CardActions } from "@mui/material";

import { AWSImageField } from "../components";

const IdolFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="word" alwaysOn />
  </Filter>
);

const Actions = (props) => (
  <CardActions style={{ alignSelf: "center" }}>
    <CreateButton />
  </CardActions>
);

const IdolList = (props) => (
  <List {...props} filters={<IdolFilter />} actions={<Actions />}>
    <Datagrid rowClick="edit">
      <AWSImageField circle src="picture" />
      <TextField source="firstName" />
      <TextField source="lastName" />
      <TextField source="username" />
      <TextField source="nominatedBy.name" label="Nominated By" />
      <TextField source="verification.status" label="Verification" />
      <TextField source="wallet[0].balance" label="Balance" />
      <EmailField source="status.value" label="Status" />
    </Datagrid>
  </List>
);

export default IdolList;
