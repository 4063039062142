import React from "react";
import {
  Create,
  SelectInput,
  SimpleForm,
  TextInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
} from "react-admin";
import { RichTextInput } from "ra-input-rich-text";
import { MyRichTextInput } from "../components";

const BlogPostCreate = (props) => {
  const optionRenderer = (row) => {
    return `${row.firstName || "-"} ${row.lastName || "-"}`;
  };
  return (
    <Create {...props} title="Create Blog Post">
      <SimpleForm>
        <TextInput source="title" validation={{ required: true }} />
        <TextInput
          multiline
          rows={10}
          source="summary"
          validation={{ required: true }}
        />

        <MyRichTextInput source="body" validation={{ required: true }} />

        <SelectInput
          source="locale"
          choices={[
            { id: "en", name: "English" },
            { id: "de", name: "Deutsch" },
            { id: "fa", name: "فارسی" },
          ]}
          validation={{ required: true }}
        />

        {/* <ReferenceArrayInput source="relatedIdolIds" reference="Idol">
          <AutocompleteArrayInput optionText={optionRenderer} />
        </ReferenceArrayInput> */}
      </SimpleForm>
    </Create>
  );
};

export default BlogPostCreate;
