import React from "react";
import {
  List,
  Datagrid,
  TextField,
  Filter,
  TextInput,
  FunctionField,
  EmailField,
} from "react-admin";

import {
  UserNameReference,
  UserPictureReference,
  CustomDateField,
} from "../components";

const SupportFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="word" alwaysOn />
  </Filter>
);

const SupportList = (props) => (
  <List {...props} filters={<SupportFilter />}>
    <Datagrid rowClick="edit">
      <UserPictureReference />
      <UserNameReference />
      <EmailField label="Email" render={(r) => r?.user?.email || r.email} />
      <FunctionField
        label="Phone"
        render={(r) =>
          r?.user?.callingCode && r?.user?.mobileNumber
            ? `${r.user.callingCode || ""}${r.user.mobileNumber || ""}`
            : r.phone || "-"
        }
      />
      <TextField source="title" />
      <TextField source="message" />
      <CustomDateField source="created" showTime />
    </Datagrid>
  </List>
);

export default SupportList;
