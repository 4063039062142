import React, { useEffect, useState } from "react";
import {
  Create,
  SelectInput,
  SimpleForm,
  TextInput,
  FormDataConsumer,
  useCreate,
  useRedirect,
  useRecordContext,
} from "react-admin";
import { Grid } from "@mui/material";
import MultiSection from "./multi-section";

const PageCreate = (props) => {
  const redirect = useRedirect();
  const [sections, setSections] = useState([
    {
      title: "",
      body: "",
      questions: [
        {
          question: "",
          answer: "",
        },
      ],
    },
  ]);
  const [create, { data }] = useCreate();

  useEffect(() => {
    if (data) {
      redirect(`/Page/${data.id}`);
    }
  }, [data]);

  const save = (values) => {
    let data = {
      type: values.type,
      title: values.title,
      locale: values.locale,
      sections,
    };

    create("Page", { data });
  };

  return (
    <Create {...props} title="Create Blog Post">
      <Grid container padding={3} md={8}>
        <SimpleForm onSubmit={save}>
          <TextInput source="title" validation={{ required: true }} />
          <SelectInput
            source="type"
            choices={[
              { id: "default", name: "Default" },
              { id: "terms", name: "Terms" },
              { id: "idols", name: "Idols" },
              { id: "business", name: "Business" },
            ]}
          />
          <SelectInput
            source="locale"
            choices={[
              { id: "en", name: "English" },
              { id: "de", name: "Deutsch" },
              { id: "fa", name: "فارسی" },
            ]}
            validation={{ required: true }}
          />

          <FormDataConsumer>
            {({ formData, ...rest }) => (
              <MultiSection
                formData={formData}
                sections={sections}
                setSections={setSections}
                {...rest}
              />
            )}
          </FormDataConsumer>
        </SimpleForm>
      </Grid>
    </Create>
  );
};

export default PageCreate;
