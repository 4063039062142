import React from "react";
import { Container, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useGetList } from "react-admin";
import Reports from "./Reports";
import PendingIdols from "./PendingIdols";
import NewUsers from "./NewUsers";
import PendingWithdrawals from "./PendingWithdrawal";
import { RegisteredUsersByMonth, RegisteredIdolsByMonth } from "./charts";

const Spacer = () => <span style={{ width: "1em" }} />;
const VerticalSpacer = () => <span style={{ height: "1em" }} />;

const useStyles = makeStyles((theme) => ({
  container: {
    margin: "20px auto",
  },
  root: {
    flexGrow: 1,
  },
  compactReports: {
    marginBottom: 15,
  },
}));

const Dashboard = () => {
  const classes = useStyles();

  const { data: reports } = useGetList("Report", {
    filter: {
      type: "compact",
      items: [
        "idols",
        "users",
        "supports",
        "feedbacks",
        // 'transactions',
        "serviceFee",
        "donates",
        // 'payable',
        // 'withdrawal',
        "pendingWithdrawal",
        "payments",
      ],
    },
  });

  if (!reports) {
    return null;
  }
  return (
    <Container fixed className={classes.container}>
      <div className={classes.root}>
        <Grid container spacing={3} className={classes.compactReports}>
          <Reports data={reports} />
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={3}>
            <PendingIdols />
          </Grid>
          <Grid item xs={3}>
            <NewUsers reports={reports} />
          </Grid>
          <Grid item xs={3}>
            <PendingWithdrawals reports={reports} />
          </Grid>
        </Grid>

        {/* <Grid container spacing={3}>
          <Grid item xs={6}>
            <RegisteredUsersByMonth />
          </Grid>
          <Grid item xs={6}>
            <RegisteredIdolsByMonth />
          </Grid>
        </Grid> */}
      </div>
    </Container>
  );
};

export default Dashboard;
