import React from "react";
import { Edit, SimpleForm, TextInput } from "react-admin";
import { JsonField, JsonInput } from "react-admin-json-view";
const LocalEdit = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput readOnly source="id" />
        <TextInput source="local" validation={{ required: true }} />
        <JsonInput
          source="translation"
          validation={{ required: true }}
          jsonString={true} // Set to true if the value is a string, default: false
          reactJsonOptions={{
            // Props passed to react-json-view
            name: null,
            collapsed: true,
            enableClipboard: false,
            displayDataTypes: false,
          }}
        />
      </SimpleForm>
    </Edit>
  );
};

export default LocalEdit;
