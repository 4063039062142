import axios, * as others from "axios";

// const URL = "http://localhost:4000/graphql";
const URL = "https://api.bloggercube.com";

const getAdmin = (token) =>
  axios({
    url: URL,
    method: "post",
    data: {
      query: `
        query getAdmin {
            getAdmin(token: "${token}" ) {
               user {
                   id
                   email
                   firstName
                   lastName
                   token
                   type
                   picture {
                    id
                    image
                   }
               }    
            }
        }
      `,
    },
  });

const authProvider = {
  login: ({ username, password }) => {
    return axios({
      url: URL,
      method: "post",
      data: {
        query: `
        query getAdmin {
            getAdmin( account: "${username}", password: "${password}" ) {
               user {
                   id
                   type
                   email
                   firstName
                   lastName
                    token
                    picture {
                        id
                        image
                    }
               }    
            }
        }
      `,
      },
    })
      .then((result) => {
        if (result?.data?.data?.getAdmin?.user?.token) {
          localStorage.setItem("token", result.data.data.getAdmin.user.token);
          localStorage.setItem(
            "user",
            JSON.stringify(result.data.data.getAdmin.user)
          );
          window.location.reload();
          return Promise.resolve();
        }

        // alert('The information entered is incorrect.');
        // window.location.reload();
        return Promise.reject();
      })
      .catch((e) => {});
  },
  logout: () => {
    localStorage.removeItem("token");
    return Promise.resolve();
  },
  checkError: () => Promise.resolve(),
  checkAuth: () =>
    localStorage.getItem("token") ? Promise.resolve() : Promise.reject(),
  getPermissions: () => Promise.reject("Unknown method"),
  getIdentity: () => {
    const token = localStorage.getItem("token");

    if (token) {
      return getAdmin(token).then((result) => {
        if (result?.data?.data?.getAdmin?.user?.token) {
          const { user } = result.data.data.getAdmin;
          localStorage.setItem("user", JSON.stringify(user));

          return Promise.resolve({
            ...user,
            avatar: user.picture,
            fullName: `${user.firstName} ${user.lastName}`,
          });
        }
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        return Promise.reject();
      });
    }

    localStorage.removeItem("token");
    return Promise.reject();
  },
};

export default authProvider;
