import * as React from "react";
import {
  Avatar,
  Box,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import CustomerIcon from "@mui/icons-material/PersonAdd";
import { Link } from "react-router-dom";
import { useTranslate, useGetList } from "react-admin";

import CardWithIcon from "./CardWithIcon";
import { getProfilePictureSrc } from "../../utils";

const PendingIdols = () => {
  const { isLoading, data: idols } = useGetList("Idol", {
    filter: {
      verificationStatus: "pending",
    },
    pagination: { page: 1, perPage: 10 },
  });

  if (isLoading) return null;

  return (
    <CardWithIcon
      to="/Idol"
      icon={CustomerIcon}
      title="Pending Idols"
      subtitle={idols?.length || 0}
    >
      <List>
        {idols
          ? idols?.map((idol) => (
              <ListItem
                button
                to={`/Idol/${idol.id}`}
                component={Link}
                key={idol._id}
              >
                <ListItemAvatar>
                  <Avatar src={getProfilePictureSrc(idol.picture)} />
                </ListItemAvatar>
                <ListItemText primary={`${idol.firstName} ${idol.lastName}`} />
              </ListItem>
            ))
          : null}
      </List>
      <Box flexGrow="1">&nbsp;</Box>
      <Button component={Link} to="/Idol" size="small" color="primary">
        <Box p={1}>All Idols</Box>
      </Button>
    </CardWithIcon>
  );
};

export default PendingIdols;
