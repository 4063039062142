import React from "react";
import { TextInput, useRecordContext } from "react-admin";
import { RichTextInput } from "ra-input-rich-text";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  CardActions,
  IconButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import styles from "./page-create.module.scss";

const QuestionAnswer = ({
  question,
  questionIndex,
  sectionIndex,
  handleValueChange,
}) => {
  return (
    <Card>
      <CardHeader
        title={`Question ${questionIndex + 1}`}
        action={
          <CardActions>
            <IconButton
              onClick={() =>
                handleValueChange({
                  key: "remove-question",
                  questionIndex: questionIndex,
                  sectionIndex: sectionIndex,
                })
              }
            >
              <RemoveIcon />
            </IconButton>

            <IconButton
              onClick={() =>
                handleValueChange({
                  key: "add-question",
                  questionIndex: questionIndex,
                  sectionIndex: sectionIndex,
                })
              }
            >
              <AddIcon />
            </IconButton>
          </CardActions>
        }
      />
      <Divider />

      <CardContent>
        <Grid container spacing={1}>
          <Grid item md={6} xs={12}>
            <TextInput
              fullWidth
              source={`section-${sectionIndex}-question-${questionIndex}`}
              label="Question"
              value={question.question || ""}
              defaultValue={question.question || ""}
              onChange={(e) =>
                handleValueChange({
                  key: "question",
                  questionIndex: questionIndex,
                  sectionIndex: sectionIndex,
                  value: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextInput
              fullWidth
              source={`section-${sectionIndex}-answer-${questionIndex}`}
              label="Answer"
              value={question.answer || ""}
              defaultValue={question.answer || ""}
              onChange={(e) =>
                handleValueChange({
                  key: "answer",
                  questionIndex: questionIndex,
                  sectionIndex: sectionIndex,
                  value: e.target.value,
                })
              }
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const Section = ({
  section,
  addSection,
  removeSection,
  index,
  setSections,
  handleValueChange,
}) => {
  return (
    <Card
      style={{
        marginTop: "2rem",
      }}
      // some options for better looking cards
      elevation={3}
    >
      {/* Header includes title and actions */}
      <CardHeader
        title={`Section ${index + 1}`}
        action={
          <CardActions>
            <IconButton onClick={() => removeSection(index)}>
              <RemoveIcon />
            </IconButton>

            <IconButton onClick={() => addSection(index)}>
              <AddIcon />
            </IconButton>
          </CardActions>
        }
      />

      <CardContent>
        <Grid container spacing={3}>
          <Grid item md={12} xs={12}>
            <TextInput
              source={`section-${index}-title`}
              validation={{ required: true }}
              label="Title"
              value={section.title || ""}
              defaultValue={section.title || ""}
              onChange={(e) =>
                handleValueChange({
                  key: "title",
                  sectionIndex: index,
                  value: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <RichTextInput
              source={`section-${index}-body`}
              validation={{ required: true }}
              label="Body"
              value={section.body || ""}
              defaultValue={section.body || ""}
              rows={10}
              onChange={(value) =>
                handleValueChange({
                  key: "body",
                  sectionIndex: index,
                  value,
                })
              }
            />
          </Grid>

          <Grid item md={12} xs={12}>
            {section.questions?.map?.((obj, objIndex) => (
              <QuestionAnswer
                key={`section-${index}-question-doc-${objIndex}`}
                question={obj}
                questionIndex={objIndex}
                sectionIndex={index}
                handleValueChange={handleValueChange}
              />
            ))}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const MultiSection = ({ formData, sections, setSections }) => {
  const [showSections, setShowSections] = React.useState(false);

  const record = useRecordContext();

  React.useEffect(() => {
    if (record) {
      const newSections = [];
      const { title, body, sections } = record;
      if (sections) {
        sections.forEach((item, index) => {
          newSections.push({
            title: item.title,
            body: item.body,
            questions: item.questions,
          });
        });
      }
      setSections((prev) => {
        if (!showSections) {
          setShowSections(true);
        }
        return newSections;
      });
    } else {
      setShowSections(true);
    }
  }, [record]);

  const addSection = (afterIndex = null) => {
    const newSections = [...sections];
    const newSection = {
      title: "",
      body: "",
      questions: [{ question: "", answer: "" }],
    };
    if (afterIndex === null) {
      newSections.push(newSection);
    } else {
      newSections.splice(afterIndex + 1, 0, newSection);
    }

    setSections(newSections);
  };

  const removeSection = (index) => {
    if (sections.length === 1) {
      return;
    }

    const newSections = [...sections];
    newSections.splice(index, 1);

    setSections(newSections);
  };

  const handleValueChange = ({ key, value, questionIndex, sectionIndex }) => {
    const newSections = [...sections];

    if (["question", "answer"].includes(key)) {
      newSections[sectionIndex].questions[questionIndex][key] = value;
    } else if (["add-question", "remove-question"].includes(key)) {
      if (key === "add-question") {
        newSections[sectionIndex].questions.splice(questionIndex + 1, 0, {
          question: "",
          answer: "",
        });
      } else {
        // Don't allow removing the last question
        if (newSections[sectionIndex].questions.length === 1) {
          return;
        }

        newSections[sectionIndex].questions.splice(questionIndex, 1);
      }
    } else {
      newSections[sectionIndex][key] = value;
    }

    setSections(newSections);
  };

  if (!showSections) {
    return null;
  }

  return (
    <Card className={styles.QA_sections}>
      <CardHeader title={formData.title} />
      <Divider />
      <CardContent>
        {sections.map((section, index) => (
          <Section
            index={index}
            key={`section-${index}`}
            section={section}
            handleValueChange={handleValueChange}
            addSection={addSection}
            removeSection={removeSection}
          />
        ))}
      </CardContent>
    </Card>
  );
};

export default MultiSection;
