import {
  DefaultEditorOptions,
  RichTextInput,
  RichTextInputToolbar,
  LevelSelect,
  FormatButtons,
  AlignmentButtons,
  ListButtons,
  LinkButtons,
  QuoteButtons,
  ClearButtons,
  useTiptapEditor,
} from "ra-input-rich-text";
import HorizontalRule from "@tiptap/extension-horizontal-rule";
import Remove from "@mui/icons-material/Remove";
import { ToggleButton } from "@mui/material";
import { AiModal } from "../ai";

const MyRichTextInputToolbar = ({ size, ...props }) => {
  const editor = useTiptapEditor();

  return (
    <RichTextInputToolbar {...props}>
      <LevelSelect size={size} />
      <FormatButtons size={size} />
      <AlignmentButtons size={size} />
      <ListButtons size={size} />
      <LinkButtons size={size} />
      <QuoteButtons size={size} />
      <ClearButtons size={size} />
      <ToggleButton
        aria-label="Add an horizontal rule"
        title="Add an horizontal rule"
        value="left"
        onClick={() => editor.chain().focus().setHorizontalRule().run()}
        selected={editor && editor.isActive("horizontalRule")}
      >
        <Remove fontSize="inherit" />
      </ToggleButton>

      <AiModal />
    </RichTextInputToolbar>
  );
};

const MyRichTextInput = ({ size, ...props }) => (
  <RichTextInput
    editorOptions={MyEditorOptions}
    toolbar={<MyRichTextInputToolbar size={size} />}
    label="Body"
    source="body"
    {...props}
  />
);

export const MyEditorOptions = {
  ...DefaultEditorOptions,
  extensions: [...DefaultEditorOptions.extensions, HorizontalRule],
};

export default MyRichTextInput;
