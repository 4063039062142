import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  DateInput,
  RadioButtonGroupInput,
} from "react-admin";
import { Card } from "@mui/material";
import { AWSImageInput, CustomDateInput } from "../components";

const UserTitle = ({ record }) =>
  record ? (
    <span>
      {record.firstName} {record.lastName}
    </span>
  ) : null;

const UserEdit = (props) => (
  <Card>
    <Edit {...props} title={<UserTitle />}>
      <SimpleForm>
        <AWSImageInput />

        <TextInput readOnly source="id" />
        <TextInput source="firstName" />
        <TextInput source="lastName" />
        <TextInput source="email" />
        <TextInput source="callingCode" />
        <TextInput source="mobileNumber" />
        <TextInput source="balance" />
        <CustomDateInput source="birthDate" />

        <RadioButtonGroupInput
          source="gender"
          choices={[
            { id: "male", name: "Male" },
            { id: "female", name: "Female" },
            { id: "diverse", name: "Diverse" },
          ]}
        />
        <DateInput readOnly source="created" />
        <DateInput readOnly source="updated" />
      </SimpleForm>
    </Edit>
  </Card>
);

export default UserEdit;
