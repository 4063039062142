import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  CreateButton,
  Filter,
  TextInput,
} from "react-admin";
import { CardActions } from "@mui/material";

import { AWSImageField } from "../components";

const Actions = (props) => (
  <CardActions style={{ alignSelf: "center" }}>
    <CreateButton />
  </CardActions>
);

const UserFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="word" alwaysOn />
  </Filter>
);

const UserList = (props) => (
  <List {...props} filters={<UserFilter />} actions={<Actions />}>
    <Datagrid rowClick="edit">
      <AWSImageField circle src="picture" />
      <TextField source="firstName" />
      <TextField source="lastName" />
      <TextField source="wallet[0].balance" label="Balance" />
      <EmailField source="status.value" label="Status" />
    </Datagrid>
  </List>
);

export default UserList;
