import { CardActions } from "@mui/material";
import React from "react";
import { List, Datagrid, TextField, CreateButton } from "react-admin";
import { CustomDateField } from "../components";

const Actions = (props) => (
  <CardActions style={{ alignSelf: "center" }}>
    <CreateButton />
  </CardActions>
);

const BlogPostList = (props) => (
  <List {...props} actions={<Actions />}>
    <Datagrid rowClick="edit">
      <TextField source="local" />
      <CustomDateField source="updated" />
    </Datagrid>
  </List>
);

export default BlogPostList;
