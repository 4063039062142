import * as React from "react";
import {
  Avatar,
  Box,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
} from "@mui/material";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { Link } from "react-router-dom";
import { useGetList } from "react-admin";

import CardWithIcon from "./CardWithIcon";
import { getProfilePictureSrc } from "../../utils";

const PendingWithdrawals = (props) => {
  const { reports } = props;

  const { isLoading, data: withdrawals } = useGetList("Withdrawal", {
    filter: {
      status: "pending",
    },
    pagination: { page: 1, perPage: 2 },
  });

  if (isLoading) return null;

  let targetReport = {};
  if (reports) {
    targetReport = reports.find((x) => x.type === "pendingWithdrawal");
  }

  return (
    <CardWithIcon
      to="/Withdrawal"
      icon={AccountBalanceWalletIcon}
      title="Pending Withdrawal"
      subtitle={`${targetReport?.value || 0}€`}
    >
      <List>
        {withdrawals
          ? withdrawals?.map((item) => (
              <ListItem
                button
                to={`/Withdrawal/${item.id}`}
                component={Link}
                key={item._id}
              >
                <ListItemAvatar>
                  <Avatar src={getProfilePictureSrc(item?.user?.picture)} />
                </ListItemAvatar>
                <ListItemText primary={`${item.user.firstName}`} />
                <ListItemSecondaryAction>
                  <span>{item.amount}€</span>
                </ListItemSecondaryAction>
              </ListItem>
            ))
          : null}
      </List>
      <Box flexGrow="1">&nbsp;</Box>
      <Button component={Link} to="/Withdrawal" size="small" color="primary">
        <Box p={1}>All Withdrawals</Box>
      </Button>
    </CardWithIcon>
  );
};

export default PendingWithdrawals;
