import React from "react";
import {
  List,
  Datagrid,
  TextField,
  Filter,
  TextInput,
  NumberField,
  BooleanField,
  FunctionField,
} from "react-admin";

import {
  CustomDateField,
  UserNameReference,
  UserPictureReference,
} from "../components";

const FeedbackFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="word" alwaysOn />
  </Filter>
);

const FeedbackList = (props) => (
  <List {...props} filters={<FeedbackFilter />}>
    <Datagrid rowClick="edit">
      <UserPictureReference />
      <UserNameReference />
      <TextField source="user.firstName" label="First Name" />
      <TextField source="user.lastName" label="Last Name" />
      <TextField source="message" />
      <NumberField source="score" />
      <CustomDateField source="created" />
    </Datagrid>
  </List>
);

export default FeedbackList;
