import React, { useEffect, useMemo, useState } from "react";
import { Admin, Resource } from "react-admin";
import { ToastContainer, toast } from "react-toastify";
import buildGraphQLProvider, { buildQuery } from "ra-data-graphql-simple";
import gql from "graphql-tag";
import "./assets/styles/main.scss";
import { ApolloClient } from "apollo-client";
import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import { InMemoryCache } from "apollo-cache-inmemory";
import UserIcon from "@mui/icons-material/Group";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import { PhoneIphoneOutlined, SupervisedUserCircle } from "@mui/icons-material";
import PaymentIcon from "@mui/icons-material/Payment";
import TeamMemberIcon from "@mui/icons-material/Workspaces";
import CategoryIcon from "@mui/icons-material/Category";
import StarsIcon from "@mui/icons-material/Stars";
import HelpIcon from "@mui/icons-material/Help";
import NotificationIcon from "@mui/icons-material/NotificationsActive";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import CharityIcon from "@mui/icons-material/EmojiPeople";
import BlogIcon from "@mui/icons-material/RssFeed";
import LanguageIcon from "@mui/icons-material/Language";
import PageIcon from "@mui/icons-material/ArticleOutlined";
import RoomsIcon from "@mui/icons-material/Groups";
import { QueryClient } from "react-query";
import authProvider from "./authProvider";

import "react-toastify/dist/ReactToastify.css";

import {
  users,
  idols,
  partners,
  categories,
  feedbacks,
  Dashboard,
  support,
  withdrawals,
  payments,
  notifications,
  calls,
  blogPosts,
  locals,
  charities,
  pages,
  teamMember,
  rooms,
} from "./ui";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      structuralSharing: false,
    },
    mutations: {
      retryDelay: 10000,
    },
  },
});

const requestTypes = {
  User: "User",
  Idol: "Idol",
  Partner: "Partner",
  TeamMember: "TeamMember",
};

const itemsFields = {
  User: `
    id
    firstName
    lastName
    username
    email
    mobileNumber
    callingCode
    token
    picture {
      id
      image
      original
    }
    balance
    about
    birthDate
    gender
    profileCompleted
    created
    updated
  `,
  Idol: `
    id
    firstName
    lastName
    username
    email
    mobileNumber
    callingCode
    token
    picture {
      id
      image
      original
    }
    categoryId
    balance
    taxId
    businessRegistration
    iban
    bic
    about
    birthDate
    gender
    verificationStatus
    verification {
      image {
        id
        image
        original
      }
      status
      text
      documentType
      links {
        type
        value
      }
    }
    profileCompleted
    featured
    created
    updated
  `,
  Partner: `
    id
    firstName
    name
    email
    mobileNumber
    callingCode
    token
    picture {
      id
      image
      original
    }
    balance
    about
    birthDate
    gender
    profileCompleted
    created
    updated
  `,
  TeamMember: `
    id
    firstName
    lastName
    username
    email
    mobileNumber
    callingCode
    token
    picture {
      id
      image
      original
    }
    balance
    about
  `,
};

const App = () => {
  const TOKEN = localStorage.getItem("token");

  let user = localStorage.getItem("user");
  if (user) {
    user = JSON.parse(user);
  }

  useEffect(() => {}, [TOKEN]);

  const httpLink = createHttpLink({
    // uri: "http://localhost:4000/graphql",
    uri: "https://api.bloggercube.com",
  });

  const authLink = setContext((_, { headers }) => ({
    headers: {
      ...headers,
      authorization: TOKEN || "",
    },
  }));

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });

  const customBuildQuery = (introspection) => (fetchType, resource, params) => {
    const bq = buildQuery(introspection)(fetchType, resource, params);

    if (fetchType === "GET_ONE" && requestTypes[resource]) {
      const query = gql`
                query ${resource}($id: ID!) {
                  ${resource}(id: $id) {
                      ${itemsFields[resource]}
                  }
                }
              `;

      const parseResponse = (res) => {
        const { data } = res;

        return {
          data: data?.[resource] || {},
        };
      };

      return {
        ...bq,
        query,
        variables: params,
        parseResponse,
      };
    }

    return bq;
  };

  const [dataProvider, setDataProvider] = useState(null);
  const [state, setState] = useState({
    token: TOKEN || false,
    key: Math.random(),
  });

  const showNotification = (message, type) => {
    if (type === "error") {
      toast.error(message, {
        position: "bottom-right",
      });
    } else {
      toast(message, {
        position: "bottom-right",
      });
    }
  };

  useEffect(() => {
    const fetchDataProvider = async () => {
      try {
        const provider = await buildGraphQLProvider({
          client,
          // buildQuery: customBuildQuery,
        });

        const customProvider = {
          ...provider,
          create: async (resource, params) => {
            try {
              console.log("Create operation:", resource, params);
              const res = await provider.create(resource, params);

              if (res?.data?.error?.message) {
                throw new Error(res?.data?.error?.message);
              } else {
                return res;
              }
            } catch (error) {
              showNotification(
                error.message || JSON.stringify(error || {}),
                "error"
              );
            }
          },

          update: async (resource, params) => {
            try {
              console.log("Update operation:", resource, params);
              const res = await provider.update(resource, params);

              if (res?.data?.error?.message) {
                throw new Error(res?.data?.error?.message);
              } else {
                return res;
              }
            } catch (error) {
              showNotification(
                error.message || JSON.stringify(error || {}),
                "error"
              );
            }
          },
        };

        setDataProvider(() => customProvider);
      } catch (error) {
        console.log("Error setting up data provider:", error);
        console.log(`Error setting up data provider: ${error.message}`, {
          type: "warning",
        });
      }
    };

    fetchDataProvider();
  }, []);

  const ADMIN_COMPONENTS = useMemo(() => {
    return (
      <>
        <Resource
          name="User"
          icon={UserIcon}
          list={users.list}
          edit={users.edit}
          create={users.create}
        />

        <Resource
          name="Idol"
          icon={VerifiedUserIcon}
          list={idols.list}
          edit={idols.edit}
          create={idols.create}
        />

        <Resource
          name="Partner"
          icon={SupervisedUserCircle}
          list={partners.list}
          edit={partners.edit}
          create={partners.create}
        />

        <Resource
          name="TeamMember"
          icon={TeamMemberIcon}
          list={teamMember.list}
          edit={teamMember.edit}
          create={teamMember.create}
          options={{
            label: "Team Member",
          }}
        />

        <Resource
          name="Withdrawal"
          icon={AccountBalanceWalletIcon}
          list={withdrawals.list}
        />

        <Resource name="Call" icon={PhoneIphoneOutlined} list={calls.list} />

        <Resource name="Room" icon={RoomsIcon} list={rooms.list} />

        <Resource
          name="Payment"
          icon={PaymentIcon}
          list={payments.list}
          edit={payments.edit}
        />

        <Resource
          name="BlogPost"
          icon={BlogIcon}
          list={blogPosts.list}
          edit={blogPosts.edit}
          create={blogPosts.create}
          options={{
            label: "Blog Posts",
          }}
        />

        <Resource
          name="Local"
          icon={LanguageIcon}
          list={locals.list}
          edit={locals.edit}
          create={locals.create}
          options={{
            label: "Locals",
          }}
        />

        <Resource
          name="Page"
          icon={PageIcon}
          list={pages.list}
          edit={pages.edit}
          create={pages.create}
        />

        <Resource
          name="Notification"
          icon={NotificationIcon}
          list={notifications.list}
          create={notifications.create}
        />

        <Resource
          name="Category"
          icon={CategoryIcon}
          list={categories.list}
          edit={categories.edit}
          create={categories.create}
        />

        <Resource
          name="Feedback"
          icon={StarsIcon}
          list={feedbacks.list}
          edit={feedbacks.edit}
        />

        <Resource name="Support" icon={HelpIcon} list={support.list} />

        <Resource name="Client" icon={UserIcon} />

        <Resource name="Report" icon={UserIcon} />

        <Resource
          name="Charity"
          icon={CharityIcon}
          list={charities.list}
          edit={charities.edit}
          create={charities.create}
        />
      </>
    );
  }, []);

  const EDITOR_COMPONENTS = useMemo(() => {
    return (
      <>
        <Resource
          name="BlogPost"
          icon={BlogIcon}
          list={blogPosts.list}
          edit={blogPosts.edit}
          create={blogPosts.create}
          options={{
            label: "Blog Posts",
          }}
        />

        <Resource
          name="Page"
          icon={PageIcon}
          list={pages.list}
          edit={pages.edit}
          create={pages.create}
        />

        <Resource
          name="Local"
          icon={LanguageIcon}
          list={locals.list}
          edit={locals.edit}
          create={locals.create}
          options={{
            label: "Locals",
          }}
        />
      </>
    );
  }, []);

  const BLOGGER_COMPONENTS = useMemo(() => {
    return (
      <>
        <Resource
          name="BlogPost"
          icon={BlogIcon}
          list={blogPosts.list}
          edit={blogPosts.edit}
          create={blogPosts.create}
          options={{
            label: "Blog Posts",
          }}
        />

        <Resource
          name="Page"
          icon={PageIcon}
          list={pages.list}
          edit={pages.edit}
          create={pages.create}
        />
      </>
    );
  }, []);

  const SUPPORT_COMPONENTS = useMemo(() => {
    return (
      <>
        <Resource
          name="Feedback"
          icon={StarsIcon}
          list={feedbacks.list}
          edit={feedbacks.edit}
        />

        <Resource name="Support" icon={HelpIcon} list={support.list} />
      </>
    );
  }, []);

  const FINANCIAL_COMPONENTS = useMemo(() => {
    return (
      <>
        <Resource
          name="Withdrawal"
          icon={AccountBalanceWalletIcon}
          list={withdrawals.list}
        />

        <Resource
          name="Payment"
          icon={PaymentIcon}
          list={payments.list}
          edit={payments.edit}
        />
      </>
    );
  }, []);

  const contents = useMemo(() => {
    if (user?.type) {
      switch (user.type) {
        case "admin":
          return ADMIN_COMPONENTS;
        case "editor":
          return EDITOR_COMPONENTS;
        case "blogger":
          return BLOGGER_COMPONENTS;
        case "support":
          return SUPPORT_COMPONENTS;
        case "financial":
          return FINANCIAL_COMPONENTS;
        default:
          return null;
      }
    }
    return null;
  }, [user?.type]);

  if (!dataProvider) {
    return <div>Loading</div>;
  }

  return (
    <div>
      <ToastContainer />
      <Admin
        queryClient={queryClient}
        authProvider={authProvider}
        dataProvider={dataProvider}
        dashboard={user?.type === "admin" ? Dashboard : null}
        requireAuth
      >
        {contents || (
          // React Admin Needs at least one Resource
          <Resource
            name="BlogPost"
            icon={BlogIcon}
            list={blogPosts.list}
            edit={blogPosts.edit}
            create={blogPosts.create}
            options={{
              label: "Blog Posts",
            }}
          />
        )}
      </Admin>
    </div>
  );
};

export default App;
