import React from 'react';
import {List, Datagrid, TextField, Filter, TextInput} from 'react-admin';
import {CustomDateField} from '../components';

const NotificationFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="word" alwaysOn />
  </Filter>
);

const NotificationList = (props) => (
  <List {...props} filters={<NotificationFilter />}>
    <Datagrid>
      <TextField source="title" />
      <TextField source="text" />
      <TextField source="group" />
      <CustomDateField source="created" />
    </Datagrid>
  </List>
);

export default NotificationList;
