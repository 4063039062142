import React from "react";
import { useRecordContext, ReferenceField, FunctionField } from "react-admin";

const UserNameReference = () => {
  const record = useRecordContext();

  return (
    <ReferenceField
      source="user.id"
      reference={record?.user?.type === "endUser" ? "User" : "Idol"}
    >
      <FunctionField render={(r) => `${r?.firstName} ${r.lastName}`} />
    </ReferenceField>
  );
};

export default UserNameReference;
