import * as React from "react";
import { createElement } from "react";
import { Card, Box, Typography, Divider } from "@mui/material";
import { Link } from "react-router-dom";

const CardWithIcon = (props) => {
  const { icon, title, subtitle, to, children } = props;

  return (
    <Card>
      <Link to={to}>
        <div className="CardWithIcon__header">
          <div className="iconContainer">
            <Box width="3em" className="icon">
              {createElement(icon, { fontSize: "large" })}
            </Box>
          </div>
          <Box textAlign="right" className="content">
            <Typography color="textSecondary">{title}</Typography>
            <Typography variant="h5" component="h2">
              {subtitle || " "}
            </Typography>
          </Box>
        </div>
      </Link>
      {children && <Divider />}
      {children}
    </Card>
  );
};

export default CardWithIcon;
