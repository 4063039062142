import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  SelectInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
} from "react-admin";
import { RichTextInput } from "ra-input-rich-text";
import { AWSThumbnailInput } from "../components/image";

const BlogPostTitle = ({ record }) =>
  record ? <span>{record.title}</span> : null;

const BlogPostEdit = (props) => {
  const optionRenderer = (row) => {
    return `${row?.firstName || "-"} ${row?.lastName || "-"}`;
  };
  return (
    <Edit {...props} title={<BlogPostTitle />}>
      <SimpleForm>
        <AWSThumbnailInput />
        <TextInput readOnly source="id" />
        <TextInput source="title" validation={{ required: true }} />
        <TextInput source="summary" validation={{ required: true }} />
        <RichTextInput source="body" validation={{ required: true }} />
        <SelectInput
          source="locale"
          choices={[
            { id: "en", name: "English" },
            { id: "de", name: "Deutsch" },
            { id: "fa", name: "فارسی" },
          ]}
          validation={{ required: true }}
        />
        {/* <ReferenceArrayInput source="relatedIdolIds" reference="Idol">
          <AutocompleteArrayInput optionText={optionRenderer} />
        </ReferenceArrayInput> */}
        <BooleanInput source="deleted" />
      </SimpleForm>
    </Edit>
  );
};

export default BlogPostEdit;
