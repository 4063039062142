import React from "react";
import { Create, SimpleForm, TextInput } from "react-admin";

const CharityCreate = (props) => (
  <Create {...props} title="Create Charity">
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="description" />
      <TextInput source="website" />
    </SimpleForm>
  </Create>
);

export default CharityCreate;
