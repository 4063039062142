import React, { useEffect, useState } from "react";
import {
  Edit,
  SimpleForm,
  FormDataConsumer,
  TextInput,
  BooleanInput,
  SelectInput,
  SaveButton,
  DeleteButton,
  Toolbar,
  useUpdate,
  useRedirect,
} from "react-admin";
import { AWSThumbnailInput } from "../components/image";
import MultiSection from "./multi-section";

const PageTitle = ({ record }) => (record ? <span>{record.title}</span> : null);

const PageEditToolbar = (props) => (
  <Toolbar {...props} style={{ justifyContent: "space-between" }}>
    <SaveButton alwaysEnable />
    <DeleteButton />
  </Toolbar>
);

const PageEdit = (props) => {
  const redirect = useRedirect();
  const [sections, setSections] = useState([
    {
      title: "",
      body: "",
      questions: [
        {
          question: "",
          answer: "",
        },
      ],
    },
  ]);
  const [update, { data }] = useUpdate();
  const save = (values) => {
    // remove __typename from sections
    sections.forEach((section) => {
      delete section.__typename;
      section.questions.forEach((question) => {
        delete question.__typename;
      });
    });

    let data = {
      type: values.type,
      title: values.title,
      locale: values.locale,
      sections,
    };

    // Check if there is any section and question/answer

    update("Page", { id: values.id, data });
  };

  useEffect(() => {
    if (data) {
      redirect("/Page");
    }
  }, [data]);

  return (
    <Edit {...props} title={<PageTitle />}>
      <SimpleForm onSubmit={save} toolbar={<PageEditToolbar />}>
        <AWSThumbnailInput source="Page" />
        <TextInput readOnly source="id" />
        <TextInput source="title" validation={{ required: true }} />
        <SelectInput
          source="type"
          choices={[
            { id: "default", name: "Default" },
            { id: "terms", name: "Terms" },
            { id: "idols", name: "Idols" },
            { id: "business", name: "Business" },
          ]}
        />
        <SelectInput
          source="locale"
          choices={[
            { id: "en", name: "English" },
            { id: "de", name: "Deutsch" },
            { id: "fa", name: "فارسی" },
          ]}
          validation={{ required: true }}
        />

        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <MultiSection
              formData={formData}
              sections={sections}
              setSections={setSections}
              {...rest}
            />
          )}
        </FormDataConsumer>

        <BooleanInput source="deleted" />
      </SimpleForm>
    </Edit>
  );
};

export default PageEdit;
