import React, { useState } from "react";
import { DateField, DateInput, useRecordContext } from "react-admin";
import moment from "moment";
import { Button } from "@mui/material";

const CustomDateField = (props) => {
  const record = useRecordContext();

  const recordWithTimestampAsInteger = {
    [props.source]: parseInt(
      record[props.source] || props.record[props.source],
      10
    ),
  };
  return (
    <DateField
      {...props}
      options={{
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      }}
      record={recordWithTimestampAsInteger}
    />
  );
};

const CustomDateInput = (props) => {
  const record = useRecordContext();
  const { source } = props;

  const [isVisibleInput, setIsVisibleInput] = useState(
    !(record && source && record[source])
  );

  if (isVisibleInput) {
    return (
      <DateInput
        source={source}
        label={source}
        options={{
          disableFuture: true,
          openTo: "year",
          format: "dd/MM/yyyy",
          label: "Date of birth",
          views: ["year", "month", "date"],
        }}
      />
    );
  }
  return (
    <p>
      {moment.unix(record[source] / 1000).format("DD-MM-YYYY - HH:mm:ss")}
      <Button color="primary" onClick={() => setIsVisibleInput(true)}>
        Change
      </Button>
    </p>
  );
};

export { CustomDateField, CustomDateInput };
