import React from "react";
import { Edit, SimpleForm, TextInput, BooleanInput } from "react-admin";
import { AWSThumbnailInput } from "../components/image";

const CategoryTitle = ({ record }) =>
  record ? <span>{record.name}</span> : null;

const CategoryEdit = (props) => (
  <Edit {...props} title={<CategoryTitle />}>
    <SimpleForm>
      <AWSThumbnailInput source="Category" />

      <TextInput readOnly source="id" />
      <TextInput source="name" />
      <BooleanInput source="deleted" />
    </SimpleForm>
  </Edit>
);

export default CategoryEdit;
