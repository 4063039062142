import React from "react";
import {
  List,
  Datagrid,
  TextField,
  Filter,
  TextInput,
  FunctionField,
} from "react-admin";

import { UserPictureReference, CustomDateField } from "../components";

const RoomnFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="word" alwaysOn />
  </Filter>
);

const RoomnList = (props) => {
  return (
    <List {...props} filters={<RoomnFilter />}>
      <Datagrid>
        <FunctionField
          label="Creator"
          render={(r) => (
            <a
              href={`https://admin.bloggercube.com/#/Idol/${r.creator.userId}`}
              className="user-avatar-container"
            >
              <div className={`awsImageField--size-tiny circle`}>
                <img alt="" src={r.creator.avatar} />
              </div>

              <div className="avatar-name">
                {`${r.creator.firstName || ""} ${r.creator.lastName || ""}`}
              </div>
            </a>
          )}
        />

        <TextField source="title" />

        <FunctionField
          label="Speaker"
          render={(r) => (
            <div className="users-group">
              <div className="avatars">
                {!!r.speaker?.length ? (
                  <>
                    {r.speaker.slice(0, 2).map((u) => (
                      <div className={`avatar awsImageField--size-tiny circle`}>
                        <img alt="" src={u.avatar} />
                      </div>
                    ))}

                    {r.speakersCount > 2 && (
                      <div
                        className={`avatar awsImageField--size-tiny circle countCoutainer`}
                      >
                        <p>+{r.speakersCount - 2}</p>
                      </div>
                    )}
                  </>
                ) : (
                  "-"
                )}
              </div>
            </div>
          )}
        />

        <FunctionField
          label="Audience"
          render={(r) => (
            <div className="users-group">
              <div className="avatars">
                {!!r.audience?.length ? (
                  <>
                    {r.audience.slice(0, 2).map((u) => (
                      <div className={`avatar awsImageField--size-tiny circle`}>
                        <img alt="" src={u.avatar} />
                      </div>
                    ))}

                    {r.audienceCount > 2 && (
                      <div
                        className={`avatar awsImageField--size-tiny circle countCoutainer`}
                      >
                        <p>+{r.audienceCount - 2}</p>
                      </div>
                    )}
                  </>
                ) : (
                  "-"
                )}
              </div>
            </div>
          )}
        />

        <TextField source="status" />
        <CustomDateField source="createdAt" showTime />
      </Datagrid>
    </List>
  );
};

export default RoomnList;
