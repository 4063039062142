import { print } from "graphql";
import axios from "axios";
import { GET_UPLOAD_URL } from "./graphql/mutations";

// const GRAPHQL_ENDPOINT = "http://localhost:4000/graphql";
const GRAPHQL_ENDPOINT = "https://api.bloggercube.com";

const getProfilePictureSrc = (picture, size = "small") => {
  console.log("picture", picture);
  try {
    if (picture && picture?.original !== "" && size) {
      let sizeStr = "w=172&h=215";
      const quality = "&90";
      const options = "&auto=enhance";

      switch (size) {
        case "small":
          sizeStr = "w=320&h=400";
          break;
        case "medium":
          sizeStr = "w=320&h=400";
          break;
        case "featured":
          sizeStr = "w=640&h=800";
          break;
        default:
          sizeStr = "w=172&h=215";
          break;
      }

      return `https://bloggercube.imgix.net/${picture.original}?${sizeStr}${quality}${options}`;
    }
    return null;
  } catch (e) {
    console.log("error_picture", picture);
    console.log("error", e);
    return null;
  }
};

const getThumbnailSrc = (thumbnail, size = "small") => {
  try {
    if (thumbnail && thumbnail?.original !== "" && size) {
      let sizeStr = "w=172&h=215";
      const quality = "&90";
      const options = "&auto=enhance";

      switch (size) {
        case "medium":
          sizeStr = "w=640&h=360";
          break;
        case "tiny":
          sizeStr = "w=100&h=56";
          break;
        default:
          sizeStr = "w=640&h=360";
          break;
      }

      return `https://bloggercube.imgix.net/${thumbnail.original}?${sizeStr}${quality}${options}`;
    }
    return null;
  } catch (e) {
    console.log("error_picture", thumbnail);
    console.log("error", e);
    return null;
  }
};

const getFileExtension = (filename) =>
  /[.]/.exec(filename) ? /[^.]+$/.exec(filename)[0] : undefined;

const uploadToS3 = async (token, file, type) => {
  //  filename = filepath.substring(filepath.lastIndexOf('/') + 1);

  const extension = getFileExtension(file.name).toLowerCase();

  const res = await axios.post(
    GRAPHQL_ENDPOINT,
    {
      query: print(GET_UPLOAD_URL),
      variables: { input: { mime: file.type, extension, uploadType: type } },
    },
    {
      headers: {
        authorization: token,
      },
    }
  );

  if (res?.data?.data?.getSignedUploadUrl?.data?.data) {
    const formJson = JSON.parse(res.data.data.getSignedUploadUrl.data.data);
    const form = new FormData();
    Object.keys(formJson.fields).forEach((key) =>
      form.append(key, formJson.fields[key])
    );

    // const uri = URL.createObjectURL(file);
    // const image = new File([file], file.name);

    form.append("file", file);

    await axios
      .request({
        method: "post",
        url: formJson.url,
        data: form,
        // onUploadProgress: callback,
      })
      .then((t) => {
        // console.log("t", t);
      })
      .catch((e) => {
        console.log("e", e);
      });

    return res.data.data.getSignedUploadUrl.data.id;
  }
};

const getImageFromUrl = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", reject);
    image.src = url;
  });

const getBlobFromCanvas = (canvas, file) =>
  new Promise((resolve, reject) => {
    canvas.toBlob((blob) => {
      if (blob) {
        blob.name = file.name;
        blob.lastModified = file.lastModified;
        resolve(blob);
      } else {
        reject(new Error("Canvas is empty"));
      }
    }, file.type); // "image/jpeg");
  });

const getCroppedImg = async (imageUrl, file, crop) => {
  const image = await getImageFromUrl(imageUrl);
  const canvas = document.createElement("canvas");
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  const ctx = canvas.getContext("2d");

  canvas.width = crop.width;
  canvas.height = crop.height;

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height
  );

  return await getBlobFromCanvas(canvas, file);
};

export { getProfilePictureSrc, getThumbnailSrc, getCroppedImg, uploadToS3 };
