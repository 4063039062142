import React from "react";
import { List, Datagrid, TextField, Filter, TextInput } from "react-admin";

import {
  UserNameReference,
  UserPictureReference,
  CustomDateField,
} from "../components";

const CommunicationFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="word" alwaysOn />
  </Filter>
);

const CommunicationList = (props) => {
  return (
    <List {...props} filters={<CommunicationFilter />}>
      <Datagrid>
        <UserPictureReference label="From" node="from" />
        <UserPictureReference label="To" node="to" />
        <UserNameReference />
        <TextField source="from.firstName" label="From User" emptyText="-" />
        <TextField source="to.firstName" label="To User" emptyText="-" />
        <TextField source="reference" emptyText="-" />
        <TextField source="seconds" emptyText="-" />
        <TextField source="amount" emptyText="-" />
        <TextField source="payable" emptyText="-" />
        <TextField source="donate" emptyText="-" />
        <TextField source="status" />
        <CustomDateField source="created" showTime />
      </Datagrid>
    </List>
  );
};

export default CommunicationList;
