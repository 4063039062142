import React from "react";
import { Edit, SimpleForm, TextInput, DateInput } from "react-admin";

import { UserNameReference, UserPictureReference } from "../components";

const PaymentTitle = ({ record }) =>
  record ? (
    <span>
      {record?.user?.firstName} {record?.user?.lastName}
    </span>
  ) : null;

const SimpleFormComponent = (props) => {
  const { record } = props;
  return (
    <SimpleForm {...props}>
      <UserPictureReference />
      <UserNameReference />
      <TextInput readOnly source="id" />
      <TextInput readOnly source="amount" />
      {record.gateway && <TextInput readOnly source="gateway" />}
      {record.token && <TextInput multiline readOnly source="token" />}
      {record.paypalPaymentId && (
        <TextInput multiline readOnly source="paypalPaymentId" />
      )}
      {record.paypalToken && <TextInput readOnly source="paypalToken" />}
      {record.receipt && <TextInput multiline readOnly source="receipt" />}
      <DateInput readOnly source="created" />
      <DateInput readOnly source="updated" />
    </SimpleForm>
  );
};

const PaymentEdit = (props) => (
  <Edit {...props} title={<PaymentTitle />}>
    <SimpleFormComponent />
  </Edit>
);

export default PaymentEdit;
