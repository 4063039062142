import React from "react";
import {
  List,
  Datagrid,
  TextField,
  CreateButton,
  Filter,
  TextInput,
} from "react-admin";
import { CardActions } from "@mui/material";

import { AWSImageField } from "../components";

const Actions = (props) => (
  <CardActions style={{ alignSelf: "center" }}>
    <CreateButton />
  </CardActions>
);

const TeamMemberFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="word" alwaysOn />
  </Filter>
);

const TeamMemberList = (props) => (
  <List {...props} filters={<TeamMemberFilter />} actions={<Actions />}>
    <Datagrid rowClick="edit">
      <AWSImageField circle src="picture" />
      <TextField source="firstName" />
      <TextField source="lastName" />
      <TextField source="username" />
      <TextField source="type" />
      <TextField source="email" />
    </Datagrid>
  </List>
);

export default TeamMemberList;
