import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  SelectInput,
  ReferenceInput,
  useRecordContext,
} from "react-admin";
import { AWSImageField, AWSImageInput, CustomDateInput } from "../components";

const IdolTitle = ({ record }) =>
  record ? (
    <span>
      {record.firstName} {record.lastName}
    </span>
  ) : null;

const CustomSocialLinks = () => {
  const record = useRecordContext();
  const links = record?.verification?.links;

  const VERIFICATION_LINK_PROVIDER_TYPE = [
    "instagram",
    "telegram",
    "tiktok",
    "facebook",
    "twitter",
    "youtube",
    "linkedin",
  ];

  const getLink = (type, account) => {
    let link = "";
    if (!type || !account) return link;

    switch (type?.toLowerCase()) {
      case "instagram":
        link = `https://www.instagram.com/${account}`;
        break;
      case "telegram":
        link = `https://t.me/${account}`;
        break;
      case "tiktok":
        link = `https://www.tiktok.com/@${account}`;
        break;
      case "facebook":
        link = `https://www.facebook.com/${account}`;
        break;
      case "twitter":
        link = `https://twitter.com/${account}`;
        break;
      case "youtube":
        link = `https://www.youtube.com/channel/${account}`;
        break;
      case "linkedin":
        link = `https://www.linkedin.com/in/${account}`;
        break;

      default:
        break;
    }

    return link;
  };

  if (!links) return null;
  return (
    <ul style={{ listStyle: "none", padding: 0 }}>
      {links?.map?.((link) => (
        <li
          key={link?.type}
          style={{
            marginBottom: 20,
          }}
        >
          <a
            href={getLink(link?.type, link?.value)}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              padding: "10px 20px",
              border: "1px solid #ccc",
              borderRadius: 5,
              backgroundColor: "lightblue",
              color: "#000",
            }}
          >
            {link?.type}: <strong>{link?.value}</strong>
          </a>
        </li>
      ))}
    </ul>
  );
};

const IdolEdit = (props) => (
  <Edit {...props} title={<IdolTitle />}>
    <SimpleForm>
      <AWSImageInput />

      <TextInput source="firstName" />
      <TextInput source="lastName" />
      <TextInput source="username" />
      <TextInput source="email" />
      <TextInput source="callingCode" />
      <TextInput source="mobileNumber" />
      <ReferenceInput
        label="Category"
        source="categoryId"
        reference="Category"
        filter={{ deleted: false }}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput source="balance" />
      <TextInput source="taxId" />
      <TextInput source="businessRegistration" />
      <TextInput source="iban" />
      <TextInput source="bic" />
      <TextInput multiline source="about" />
      <h4>Social Links</h4>
      <CustomSocialLinks />
      <h4>Birthdate</h4>
      <CustomDateInput source="birthDate" />
      <SelectInput
        source="gender"
        choices={[
          { id: "male", name: "Male" },
          { id: "female", name: "Female" },
          { id: "diverse", name: "Diverse" },
        ]}
      />
      <h4>Verification</h4>
      <AWSImageField size="medium" circle src="verification.image" />
      <h4>Documents</h4>
      <AWSImageField size="medium" circle src="documents" />
      <SelectInput
        source="verificationStatus"
        choices={[
          { id: "initialized", name: "Initialized" },
          { id: "pending", name: "Pending" },
          { id: "successful", name: "Successful" },
        ]}
      />
      <BooleanInput source="profileCompleted" />
      <BooleanInput source="featured" />
      <TextInput source="password" />
    </SimpleForm>
  </Edit>
);

export default IdolEdit;
