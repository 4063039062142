import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  Filter,
  TextInput,
  BooleanField,
} from 'react-admin';
import {CustomDateField} from '../components';

const CategoryFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="word" alwaysOn />
  </Filter>
);

const CategoryList = (props) => (
  <List {...props} filters={<CategoryFilter />}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <CustomDateField source="created" />
      <BooleanField source="deleted" />
    </Datagrid>
  </List>
);

export default CategoryList;
