import * as React from "react";
import {
  Avatar,
  Box,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import UsersIcon from "@mui/icons-material/Group";
import { Link } from "react-router-dom";
import { useGetList } from "react-admin";

import CardWithIcon from "./CardWithIcon";
import { getProfilePictureSrc } from "../../utils";

const PendingUsers = (props) => {
  const { reports } = props;

  const { isLoading, data: users } = useGetList("User", {
    pagination: { page: 1, perPage: 2 },
  });

  if (isLoading) return null;

  let targetReport = {};
  if (reports) {
    targetReport = reports.find((x) => x.type === "users");
  }

  return (
    <CardWithIcon
      to="/User"
      icon={UsersIcon}
      title="Latest Users"
      subtitle={targetReport?.value || 0}
    >
      <List>
        {users
          ? users?.map((user) => (
              <ListItem
                button
                to={`/User/${user.id}`}
                component={Link}
                key={user._id}
              >
                <ListItemAvatar>
                  <Avatar src={getProfilePictureSrc(user.picture)} />
                </ListItemAvatar>
                <ListItemText primary={`${user.firstName} ${user.lastName}`} />
              </ListItem>
            ))
          : null}
      </List>
      <Box flexGrow="1">&nbsp;</Box>
      <Button component={Link} to="/User" size="small" color="primary">
        <Box p={1}>All Users</Box>
      </Button>
    </CardWithIcon>
  );
};

export default PendingUsers;
