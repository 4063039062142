import React, { useCallback, useEffect, useState, useRef } from "react";
import { Button, Card, Typography, CardActions } from "@mui/material";
import { useDropzone } from "react-dropzone";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { uploadToS3 } from "../../../utils";
import AWSThumbnailField from "./AWSThumbnailField";
import { useRecordContext, useUpdate } from "react-admin";
import { useDebounceEffect } from "../../../hooks";
import { canvasPreview } from "./canvasPreview";

const AWSThumbnailInput = (props) => {
  const { source } = props;
  let record = useRecordContext(props);

  const [upImg, setUpImg] = useState();
  const imgRef = useRef(null);
  const blobUrlRef = useRef("");
  const previewCanvasRef = useRef(null);
  const [uploading, setUploading] = useState(false);
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState(16 / 9);

  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState(null);

  const [openSelectImageWindow, setOpenSelectImageWindow] = useState(false);
  const [thumbnailId, setThumbnailId] = useState(null);

  // const [updateImage, { data }] = useMutation({
  //   type: "update",
  //   resource: "BlogPost",
  //   payload: { id: record.id, data: { id: record.id, thumbnailId } },
  // });

  const [updateImage, { data }] = useUpdate(source || "BlogPost", {
    id: record?.id,
    data: { id: record?.id, thumbnailId },
  });

  useEffect(() => {
    if (thumbnailId) {
      updateImage();
    }
  }, [thumbnailId]);

  if (data) {
    window.location.reload();
  }

  const onDrop = useCallback((files) => {
    if (files?.length) {
      const file = files[0];
      file.preview = URL.createObjectURL(file);

      setUpImg(file);
    }
    // Do something with the files
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: "image/*",
    multiple: false,
    onDrop,
  });

  const onSubmit = async (canvas, crp) => {
    try {
      if (!crp || !canvas || !previewCanvasRef?.current) {
        return;
      }
      setUploading(true);

      const token = localStorage.getItem("token");

      previewCanvasRef.current.toBlob(async (blob) => {
        if (!blob) {
          throw new Error("Failed to create blob");
        }
        if (blobUrlRef.current) {
          URL.revokeObjectURL(blobUrlRef.current);
        }
        blob.name = upImg.name;
        blobUrlRef.current = URL.createObjectURL(blob);

        if (token) {
          const picId = await uploadToS3(token, blob, "thumbnail");
          setThumbnailId(picId);
        }

        setUploading(false);
      });
    } catch (error) {
      console.log(error);
      setUploading(false);
    }
  };

  const onImageLoad = (e) => {
    const { naturalWidth: width, naturalHeight: height } = e.currentTarget;

    const crop = centerCrop(
      makeAspectCrop(
        {
          // You don't need to pass a complete crop into
          // makeAspectCrop or centerCrop.
          unit: "%",
          width: 90,
        },
        16 / 9,
        width,
        height
      ),
      width,
      height
    );

    setCrop(crop);
  };

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.

        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  return (
    <Card className="AWSThumbnailInput">
      <Typography gutterBottom variant="h5" component="h2">
        Thumbnail
      </Typography>
      <div className="inner">
        {record.thumbnail && !openSelectImageWindow ? (
          <div onClick={() => setOpenSelectImageWindow(true)}>
            <AWSThumbnailField
              size="medium"
              circle
              src="thumbnail"
              record={record}
            />
            <div className="cover">
              <Button variant="contained" color="secondary" size="small">
                Change Thumbanil
              </Button>
            </div>
          </div>
        ) : upImg ? (
          <>
            <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={(c) => setCompletedCrop(c)}
              aspect={aspect}
            >
              <img
                ref={imgRef}
                src={upImg.preview}
                alt="Crop"
                onLoad={onImageLoad}
              />
            </ReactCrop>
            <div>
              <canvas
                ref={previewCanvasRef}
                style={{
                  display: "none",
                  width: Math.round(completedCrop?.width ?? 0),
                  height: Math.round(completedCrop?.height ?? 0),
                }}
              />
            </div>
          </>
        ) : (
          <div className="dropzone-container" {...getRootProps()}>
            <input {...getInputProps()} />
            {isDragActive ? (
              <p>Drop the file here ...</p>
            ) : (
              <p>Select a thumbnail</p>
            )}
          </div>
        )}
      </div>
      {upImg && (
        <CardActions>
          <Button
            variant="contained"
            size="small"
            color={uploading ? "error" : "primary"}
            onClick={() => onSubmit(previewCanvasRef.current, completedCrop)}
            disabled={!upImg}
            loading={true}
          >
            {uploading ? "Uploading..." : "Upload"}
          </Button>
        </CardActions>
      )}
    </Card>
  );
};

export default AWSThumbnailInput;
