import React from "react";
import {
  List,
  Datagrid,
  TextField,
  Filter,
  TextInput,
  BooleanField,
} from "react-admin";
import { CustomDateField } from "../components";

const CharityFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="word" alwaysOn />
  </Filter>
);

const CharityList = (props) => (
  <List {...props} filters={<CharityFilter />} title="Charities">
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <TextField source="description" />
      <TextField source="website" />
      <TextField source="balance" />
      <CustomDateField source="createdAt" />
      <BooleanField source="deleted" />
    </Datagrid>
  </List>
);

export default CharityList;
