import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  DateInput,
  RadioButtonGroupInput,
} from "react-admin";
import { Card } from "@mui/material";
import { AWSImageInput, CustomDateInput } from "../components";

const TeamMemberTitle = ({ record }) =>
  record ? (
    <span>
      {record.firstName} {record.lastName}
    </span>
  ) : null;

const TeamMemberEdit = (props) => (
  <Card>
    <Edit {...props} title={<TeamMemberTitle />}>
      <SimpleForm>
        <AWSImageInput />

        <TextInput readOnly source="id" />
        <TextInput source="firstName" />
        <TextInput source="lastName" />
        <TextInput source="email" />
        <TextInput source="username" />

        <RadioButtonGroupInput
          source="type"
          choices={[
            { id: "admin", name: "Admin" },
            { id: "blogger", name: "Blogger" },
            { id: "editor", name: "Editor" },
            { id: "support", name: "Support" },
            { id: "financial", name: "Financial" },
          ]}
        />
        <TextInput source="password" />
      </SimpleForm>
    </Edit>
  </Card>
);

export default TeamMemberEdit;
