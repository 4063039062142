import React from 'react';
import {Create, SimpleForm, TextInput} from 'react-admin';

const FeedbackCreate = (props) => (
  <Create {...props} title="Create Feedback">
    <SimpleForm>
      <TextInput source="message" />
    </SimpleForm>
  </Create>
);

export default FeedbackCreate;
