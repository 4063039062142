import React, { useState } from "react";
import {
  List,
  Datagrid,
  TextField,
  Filter,
  TextInput,
  FunctionField,
  EmailField,
} from "react-admin";
import { print } from "graphql";
import axios from "axios";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";

import {
  UserNameReference,
  UserPictureReference,
  CustomDateField,
} from "../components";
import { ACCEPT_WITHDRAWAL, REJECT_WITHDRAWAL } from "../../graphql/mutations";

const WithdrawalFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="word" alwaysOn />
  </Filter>
);

const endpoint = process.env.REACT_APP_GRAPHQL_ENDPOINT;

const WithdrawalList = (props) => {
  const [loading, setLoading] = React.useState(false);

  const fetchAcceptWithdrawal = async (id) => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      await axios.post(
        process.env.REACT_APP_GRAPHQL_ENDPOINT,
        {
          query: print(ACCEPT_WITHDRAWAL),
          variables: { id },
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      window.location.reload();
    } catch (e) {
      console.log("error", e);
    }
    setLoading(false);
  };

  const fetchRejectWithdrawal = async (id) => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      await axios.post(
        endpoint,
        {
          query: print(REJECT_WITHDRAWAL),
          variables: { id },
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );

      window.location.reload();
    } catch (e) {
      console.log("error", e);
    }
    setLoading(false);
  };

  const onAccept = (r) => {
    if (
      window.confirm(
        `Are you sure you want to ACCEPT this withdrawal of ${r.amount}€?`
      )
    ) {
      fetchAcceptWithdrawal(r.id);
    }
  };

  const onReject = (r) => {
    // ask to reject and increase the balance of the user (r.amount)
    if (
      window.confirm(
        `Are you sure you want to REJECT this withdrawal? \n${r.amount}€ will be added back to the user's balance.`
      )
    ) {
      fetchRejectWithdrawal(r.id);
    }
  };

  return (
    <List {...props} filters={<WithdrawalFilter />}>
      <Datagrid rowClick="edit">
        <UserPictureReference />
        <UserNameReference />
        <FunctionField
          label="Name"
          render={(r) => `${r?.user?.firstName} ${r?.user?.lastName}`}
        />
        <TextField source="amount" />
        <TextField source="status" />
        <CustomDateField source="created" showTime />

        <FunctionField
          label="Action"
          render={(r) =>
            r.status === "pending" ? (
              <div className="actionButtons">
                <div className="actionButton" onClick={() => onAccept(r)}>
                  <DoneIcon color="primary" />
                </div>
                <div className="actionButton" onClick={() => onReject(r)}>
                  <CloseIcon color="error" />
                </div>
              </div>
            ) : null
          }
        />
      </Datagrid>
    </List>
  );
};

export default WithdrawalList;
