import React from "react";
import { Create, TextInput, SimpleForm } from "react-admin";

const LocalCreate = (props) => {
  return (
    <Create {...props} title="Create Local">
      <SimpleForm>
        <TextInput source="local" validation={{ required: true }} />
      </SimpleForm>
    </Create>
  );
};

export default LocalCreate;
