import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  RadioButtonGroupInput,
} from "react-admin";
import { CustomDateInput } from "../components";

const TeamMemberCreate = (props) => (
  <Create {...props} title="Create TeamMember">
    <SimpleForm>
      <TextInput source="firstName" />
      <TextInput source="lastName" />
      <TextInput source="email" />
      <TextInput source="username" />

      <RadioButtonGroupInput
        source="type"
        choices={[
          { id: "admin", name: "Admin" },
          { id: "editor", name: "Editor" },
          { id: "blogger", name: "Blogger" },
          { id: "support", name: "Support" },
          { id: "financial", name: "Financial" },
        ]}
      />
      <TextInput source="password" />
    </SimpleForm>
  </Create>
);

export default TeamMemberCreate;
