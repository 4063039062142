import * as React from "react";
import { Card, Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  card: {
    position: "relative",
  },
  shape: {
    position: "absolute",
    width: 120,
    height: 120,
    borderRadius: "30% 70% 70% 30% / 30% 30% 70% 70%",
    backgroundColor: "rgba(100, 100, 255, 0.1)",
  },
  inner: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "30px 10px",
  },
  imgContainer: {
    zIndex: 2,
  },
  img: {
    width: 50,
  },
}));

const CardWithImage = (props) => {
  const classes = useStyles();

  const { image, title, subtitle, to } = props;
  return (
    <Link to={to}>
      <Card className={classes.card}>
        <div className={classes.shape} />
        <div className={classes.inner}>
          <Box width="3em" className={classes.imgContainer}>
            <img src={image} className={classes.img} alt="" />
          </Box>
          <Box textAlign="right">
            <Typography color="textSecondary">{title}</Typography>
            <Typography variant="h5" component="h2">
              {subtitle || " "}
            </Typography>
          </Box>
        </div>
      </Card>
    </Link>
  );
};

export default CardWithImage;
