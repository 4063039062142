import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Snackbar,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { print } from "graphql";
import axios from "axios";
import { GAIC_QUERY } from "../../../graphql/queries";

const AiModal = (props) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [language, setLanguage] = useState("English");
  const [result, setResult] = useState(null);
  const [openCopied, setOpenCopied] = useState(false);

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setTitle("");
    setResult(null);
    setOpen(false);
  };

  const onSelectLanguage = (event) => {
    setLanguage(event.target.value);
  };

  const handleCopy = () => {
    setOpenCopied(true);
    navigator.clipboard.writeText(result?.content?.replace(/<[^>]*>?/gm, ""));
  };

  const onSubmit = async () => {
    try {
      if (!title) {
        return;
      }

      setLoading(true);
      const token = localStorage.getItem("token");
      const res = await axios.post(
        process.env.REACT_APP_GRAPHQL_ENDPOINT,
        {
          query: print(GAIC_QUERY),
          variables: { title, language },
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );

      setResult(res.data.data.gaic);
    } catch (e) {
      console.log("error", e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Snackbar
        message="Copied to clibboard! 📋"
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={2000}
        onClose={() => setOpenCopied(false)}
        open={openCopied}
      />

      <Button variant="contained" color="primary" onClick={openModal}>
        AI
      </Button>

      <Dialog open={open}>
        <DialogTitle>Generate Content with AI</DialogTitle>

        <DialogContent>
          <Grid direction="column" container rowSpacing={2}>
            <Grid item>
              {/* Title */}
              <TextField
                autoFocus
                required
                margin="dense"
                id="title"
                name="title"
                label="Title"
                fullWidth
                variant="standard"
                value={title || ""}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Grid>
            <Grid item>
              {/* Language */}
              <FormControl fullWidth>
                <InputLabel id="language-label">Language</InputLabel>
                <Select
                  labelId="language-label"
                  id="language"
                  value={language}
                  label="Language"
                  onChange={onSelectLanguage}
                >
                  <MenuItem value="English">English</MenuItem>
                  <MenuItem value="Deutsch">Deutsch</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {result && (
              <Grid item>
                {/* Result */}
                <div
                  style={{
                    padding: "10px",
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                    backgroundColor: "#f9f9f9",
                    maxHeight: "200px",
                    overflowY: "auto",
                  }}
                >
                  <p>{result?.content || result?.error?.message}</p>
                </div>
              </Grid>
            )}
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button disabled={loading} onClick={closeModal}>
            Cancel
          </Button>

          {result?.content ? (
            <Button variant="contained" color="primary" onClick={handleCopy}>
              Copy 📋
            </Button>
          ) : (
            <LoadingButton
              loading={loading}
              variant="contained"
              color="primary"
              onClick={onSubmit}
            >
              Generate Content
            </LoadingButton>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AiModal;
