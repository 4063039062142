import React from "react";
import { Grid } from "@mui/material";
import CardWithImage from "./CardWithImage";

const Reports = (props) => {
  const { data } = props;

  const getPath = (item) => {
    switch (item.type) {
      case "idols":
        return "Idol";
      case "users":
        return "User";
      case "supports":
        return "Support";
      case "feedbacks":
        return "Feedback";
      case "withdrawal":
      case "pendingWithdrawal":
        return "Withdrawal";
      default:
        return "";
    }
  };

  if (!data) return <p>Loading...</p>;
  return (
    <>
      {data.map((item) => (
        <Grid item xs={3}>
          <CardWithImage
            to={`/${getPath(item)}`}
            image={`https://api.bloggercube.com/api/${item.image}`}
            title={item.title}
            subtitle={`${item.value} ${item.unit}`}
          />
        </Grid>
      ))}
    </>
  );
};

export default Reports;
