import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  FormDataConsumer,
  RadioButtonGroupInput,
} from 'react-admin';
import {MultipleSelectClientsInput} from '../components';

const NotificationCreate = (props) => {
  const optionRenderer = (row) => {
    return `${row.firstName || '-'} ${row.lastName || '-'} | ${
      row.email || row.callingCode + row.mobileNumber
    }`;
  };

  return (
    <Create {...props} title="Create Notification">
      <SimpleForm>
        <TextInput source="title" />
        <TextInput multiline source="text" rows={10} />
        <RadioButtonGroupInput
          source="group"
          choices={[
            {id: 'userIds', name: 'Select User'},
            {id: 'all', name: 'All'},
            {id: 'endUsers', name: 'Users'},
            {id: 'idols', name: 'Idols'},
          ]}
        />
        <FormDataConsumer>
          {({formData, ...rest}) =>
            formData.group === 'userIds' ? (
              <ReferenceArrayInput
                source="userIds"
                reference="Client"
                filter={{hasDeviceToken: true}}>
                <AutocompleteArrayInput optionText={optionRenderer} />
              </ReferenceArrayInput>
            ) : null
          }
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
};

export default NotificationCreate;
